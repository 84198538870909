.DefaultPoster {
  background-color: rgba(black, 0.25);
  position: relative;

  &::after {
    color: rgba(white, 0.5);
    content: '\f03d';
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
