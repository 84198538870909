$site-background: #0d0d0d;
$gray-background: #333;
$red-background: #9c171a;

$link-color: #9c171a;

$error-color: #f00;
$error-background-color: #fae4e4;

$header-height: 100px;
