@import 'scss/variables';

.LoginButton {
  background: $link-color;
  border-radius: 10px;
  color: white;
  display: block;
  line-height: 60px;
  padding: 0 60px;
  text-align: center;
}
