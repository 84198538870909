@import 'scss/variables';

html,
body {
  background-color: $site-background;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Segoe UI', sans-serif;
  margin: 0;
  min-height: 100vh;
}

input,
textarea,
button {
  font-family: 'Helvetica Neue', 'Segoe UI', sans-serif;
  font-size: 16px;
}

a {
  color: $link-color;
  text-decoration: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  display: none;
}
