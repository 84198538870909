.ContentBox {
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 80px;
  text-align: center;
  width: 500px;

  & + & {
    margin-top: 10px;
  }

  &.hasTitle {
    padding: 60px 80px;
    text-align: left;
  }

  > p {
    margin: 0 -40px 40px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    padding: 20px;
    width: auto;

    &.hasTitle {
      padding: 40px 20px;
    }

    > p {
      margin: 0 0 20px;
    }
  }
}

.Title {
  color: #9c171a;
  font-family: Gala, sans-serif;
  font-size: 30px;
  margin: 0 0 40px;
  text-align: center;
  text-transform: uppercase;
}
