@import 'scss/variables';

.Camera {
  left: 60px;
  position: absolute;
  top: 80px;
}

.Avatar {
  $size: 170px;
  background-color: white;
  border: 0;
  border-radius: $size / 2;
  box-shadow: none;
  color: #ccc;
  cursor: pointer;
  display: inline-block;
  height: $size;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: $size;

  .DefaultIcon {
    line-height: $size;
  }

  .Overlay {
    background-color: #666;
    color: white;
    display: flex;
    flex-direction: column;
    height: 40%;
    justify-content: flex-end;
    left: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    top: 0;

    .CameraIcon {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .UserAvatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -1px;
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
  }

  &:not(:hover) {
    .Overlay {
      display: none;
    }
  }
}

.ButtonWrapper {
  bottom: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  left: 50%;
  max-width: 1440px;
  padding: 0 60px;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  @media (max-width: 1000px) {
    justify-content: center;
    max-width: none;
    padding: 0 20px;
  }
}

.Button {
  background-color: #666;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 55px;
  min-width: 230px;

  @media (max-width: 1000px) {
    line-height: normal;
    min-width: auto;
    padding: 10px 30px;
  }
}

.UpdateBackgroundButton {
  align-items: center;
  display: flex;
  left: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  top: 60px;

  .Icon {
    display: block;
    font-size: 26px;
    margin-right: 10px;
  }

  &:not(:hover) {
    background: transparent;
    border-color: transparent;

    .ButtonText {
      opacity: 0;
    }
  }

  @media (max-width: 1000px) {
    left: 20px;
    line-height: normal;
    padding: 10px;
    top: 20px;
  }
}

.Content {
  .Inner {
    padding: 60px;
  }

  .CreateGroupButton {
    background: none;
    border: 0;
    box-shadow: none;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin-top: 20px;
    text-decoration: none;
  }
}

.LinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  a {
    color: white;
  }
}

.ModalForm {
  .ButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;
    width: 600px;

    .ClearButton,
    .SubmitButton {
      border: 0;
      border-radius: 10px;
      font-size: 16px;
      height: 60px;
      width: calc(50% - 5px);
    }

    .ClearButton {
      background-color: rgba(black, 0.1);
    }

    .SubmitButton {
      background-color: $link-color;
      color: white;
    }
  }
}
