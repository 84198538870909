@import 'scss/variables';

.FormButton {
  background: $link-color;
  border: 0;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 60px;
  margin-top: 60px;
  width: 100%;

  & + & {
    margin-top: 20px;
  }

  &.isDisabled {
    background-color: #d0d0d0;
    cursor: default;
  }

  &.isTransparent {
    background: transparent;
    color: #443b21;
    line-height: 16px;
  }

  @media (max-width: 1000px) {
    margin-top: 40px;
  }
}
