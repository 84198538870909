@import 'scss/variables';

.TextAreaWrapper {
  margin: 0 0 40px;

  .Label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  .TextArea {
    background: rgba(#d2cebc, 0.2);
    border: 1px solid transparent;
    border-radius: 10px;
    box-sizing: border-box;
    display: block;
    max-width: 610px;
    min-height: 160px;
    outline: none;
    padding: 20px;
    resize: none;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0 1px white;
    }

    &::placeholder {
      color: rgba(black, 0.4);
    }

    &.isInvalid {
      border-color: $error-color;
    }

    &.fullWidth {
      max-width: none;
    }
  }

  .Error {
    background-color: $error-background-color;
    border: 1px solid $error-color;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    color: $error-color;
    font-size: 14px;
    margin-top: -10px;
    padding: 5px 10px;
    text-align: center;
  }

  &.DarkTheme {
    .TextArea {
      background-color: rgba(#d2cebc, 0.2);
      color: white;

      &::placeholder {
        color: rgba(#ccc, 0.4);
      }
    }
  }
}
