.Carousel {
  overflow: hidden;
  position: relative;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }
}

.CarouselInner {
  display: flex;
  transition-duration: 350ms;
  transition-property: transform;
  transition-timing-function: ease-out;
}

.CarouselItem {
  // Used to identify carousel items, not actually needed for styling
  display: block;
}

.NextButton,
.PreviousButton {
  background-color: rgba(black, 0.5);
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 20px 10px;
  position: absolute;
  transform: translateY(-50%);

  @media (max-width: 1000px) {
    display: none;
  }
}

.NextButton {
  right: 20px;
}

.PreviousButton {
  left: 20px;
}
