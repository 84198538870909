@import 'scss/variables';

$cover-height: 155px;

.CoverWrapper {
  background-color: $link-color;
  background-position: center;
  background-size: cover;
  height: $cover-height;
  margin: 0 0 20px;
  position: relative;
  width: 100%;

  &.isEditable {
    .Avatar {
      cursor: pointer;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 60px;
  }
}

.Avatar {
  $margin: 10px;
  $height: $cover-height - $margin * 2;
  background-color: rgba(white, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  height: $height;
  overflow: hidden;
  position: absolute;
  right: $margin;
  top: $margin;
  width: 200px;

  &:not(:hover) {
    .Overlay {
      display: none;
    }
  }

  &:hover {
    .OuterCameraIcon {
      display: none;

      @media (max-width: 1000px) {
        display: block;
      }
    }
  }

  @media (max-width: 1000px) {
    height: 69px;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 100px;
  }
}

.OuterCameraIcon {
  color: rgba(black, 0.5);
  font-size: 30px;
  left: 20px;
  position: absolute;
  top: 20px;

  @media (max-width: 1000px) {
    font-size: 20px;
    left: 10px;
    top: 10px;
  }
}

.Overlay {
  align-items: center;
  background-color: #666;
  color: white;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  left: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1000px) {
    display: none;
  }
}

.CameraIcon {
  font-size: 20px;
  margin-right: 10px;
}

.UpdateBackgroundButton {
  align-items: center;
  background-color: $gray-background;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  left: 10px;
  line-height: 60px;
  outline: none;
  padding: 0 20px;
  position: absolute;
  top: 10px;

  .Icon {
    color: white;
    display: block;
    font-size: 26px;
    margin-right: 10px;
  }

  &:not(:hover) {
    background: transparent;
    border-color: transparent;

    .ButtonText {
      opacity: 0;
    }
  }
}
